import React, { createContext, useContext, useEffect, useState } from "react";

interface AppStateInterface {
  favorites: number[]
  isFavoritesLoaded: boolean
  shoppingCart?: any
}

const initialState = {
  favorites: [],
  isFavoritesLoaded: false,
  shoppingCart: []
};

export const AppStateContext = createContext<AppStateInterface>(initialState);

const AppStateProvider = ({ children }: any) => {
  const [favorites, setFavorites] = useState<number[]>([]);
  const [isFavoritesLoaded, setIsFavoritesLoaded] = useState<boolean>(false);
  useEffect(() => {
    setIsFavoritesLoaded(true);
  }, []);

  const contextValue = {
    favorites,
    isFavoritesLoaded,
    setFavorites
  };

  return (
    <AppStateContext.Provider value={contextValue}>
      {children}
    </AppStateContext.Provider>
  );
};

export const useAppStateContext = () => useContext(AppStateContext);
export default AppStateProvider;
