import React, { useState, useMemo, type SyntheticEvent } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

import { ViewTitles } from "../appStrings/views";
import { COMMON_QUESTIONS, type QuestionAndAnswer } from "../appStrings/commonQuestions";
import "../scss/Help.scss";

const Help: React.FC = () => {
  const formattedList = useMemo(() => COMMON_QUESTIONS.map(i => ({ ...i, label: i.question })), []);
  const [filteredList, setFilteredList] = useState<QuestionAndAnswer[]>(formattedList);
  const handleClick = (event: SyntheticEvent<Element, Event>, value: QuestionAndAnswer | null) => {
    if (value?.id) {
      const tempList: QuestionAndAnswer[] = COMMON_QUESTIONS.filter(i => i.id === value.id);
      setFilteredList(tempList);
    } else {
      setFilteredList(formattedList);
    }
  };

  return (
    <div className="help-container container w-100 flex-column">
      <h1 className="fs-2 p-5 centering">{ViewTitles.Help}</h1>
      <Autocomplete
        disablePortal
        id="combo-box-demo"
        options={formattedList}
        sx={{ width: "100%" }}
        renderInput={(params) =>
          <TextField
            {...params}
            label="Type your question"
          />
        }
        onChange={handleClick}
      />
      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
          "& ul": { padding: 0 }
        }}
        subheader={<li />}
        className="p-3"
      >
        {filteredList.map(({ id, question, answer }) => (
          <li key={`section-${id}`}>
            <ul>
              <span className="text-title">{question}</span>
              <ListItem>
                <span className="text">{answer}</span>
              </ListItem>
            </ul>
          </li>
        ))}
      </List>
    </div>
  );
};

export default Help;
