import { type CartItem } from "../@types/product.types";
import { STORAGE_ADD_TO_CART } from "../appStrings/storage.strings";

// TODO: add lodash debounce ALL HERE
export const addToCart = (newItem: CartItem) => {
  const storedCart: string | null = sessionStorage.getItem(STORAGE_ADD_TO_CART);
  if (storedCart) {
    const oldCartArray: CartItem[] = JSON.parse(storedCart);
    const existingProduct: CartItem | undefined = oldCartArray.find(i => i.barcode === newItem?.barcode);
    if (existingProduct) {
      updateCart(newItem);
    } else {
      oldCartArray.push(newItem);
      sessionStorage.setItem(STORAGE_ADD_TO_CART, JSON.stringify(oldCartArray));
    }
  } else {
    sessionStorage.setItem(STORAGE_ADD_TO_CART, JSON.stringify([newItem]));
  }
};

export const removeFromCart = (itemBarcode: string) => {
  const storedCart: string | null = sessionStorage.getItem(STORAGE_ADD_TO_CART);
  if (storedCart) {
    const oldCartArray: CartItem[] = JSON.parse(storedCart);
    const updatedCartArray: CartItem[] = oldCartArray.filter((item) => item.barcode !== itemBarcode);
    const formattedCartObj: string = JSON.stringify(updatedCartArray);
    sessionStorage.setItem(STORAGE_ADD_TO_CART, formattedCartObj);
  }
};

export const clearCart = () => {
  sessionStorage.setItem(STORAGE_ADD_TO_CART, "");
};

export const updateCart = (newItem: CartItem) => {
  const storedCart: string | null = sessionStorage.getItem(STORAGE_ADD_TO_CART);
  if (storedCart) {
    const oldCartArray: CartItem[] = JSON.parse(storedCart);
    const existingProduct: CartItem | undefined = oldCartArray.find(i => i.barcode === newItem?.barcode);

    if (existingProduct && newItem?.quantity === 0) {
      // Item exists in the cart, but quantity is 0, remove it
      removeFromCart(newItem.barcode);
    } else if (existingProduct) {
      const formattedCart: CartItem[] = oldCartArray.map((item) => item.barcode === newItem.barcode ? newItem : item);
      sessionStorage.setItem(STORAGE_ADD_TO_CART, JSON.stringify(formattedCart));
    } else {
      // Item does not exist in the cart, add it
      const updatedCartArray: CartItem[] = [...oldCartArray, newItem];
      const formattedCartObj: string = JSON.stringify(updatedCartArray);
      sessionStorage.setItem(STORAGE_ADD_TO_CART, formattedCartObj);
    }
  } else {
    // Cart is empty, add the item
    sessionStorage.setItem(STORAGE_ADD_TO_CART, JSON.stringify([newItem]));
  }
};
