/* eslint-disable indent */
import React, { useMemo } from "react";
import { motion } from "framer-motion";

import "../scss/ButtonStyled.scss";

interface ButtonStyledProps {
  children: string | JSX.Element | JSX.Element[]
  disabled?: boolean
  onClick?: () => void
  type?: "normal" | "bigger" | "success"
}

const ButtonStyled: React.FC<ButtonStyledProps> = ({ children, disabled, onClick, type }) => {
  const buttonType = useMemo(() => {
    switch (type) {
      case "bigger":
        return "btn-bigger";
      case "success":
        return "btn-success";
      default:
        return "btn-normal";
    }
  }, [type]);

  return (
    <motion.button
      onClick={onClick}
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      className={buttonType}
      initial={{ opacity: 0, scale: 0.1 }}
      animate={{ opacity: 1, scale: 1 }}
      disabled={disabled}
    >
      {children}
    </motion.button>
  );
};

export default ButtonStyled;
