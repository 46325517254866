import { STORED_FAVORITES } from "../appStrings/localStorage";

export const getFavorites = (): string[] | null => {
  const storedFavorites = localStorage.getItem(STORED_FAVORITES);
  return storedFavorites ? JSON.parse(storedFavorites) : null;
};

export const storeOrRemoveFavorite = (incomingFavoriteBarcode: string): boolean => {
  const storedFavorites = getFavorites();
  if (!storedFavorites) {
    localStorage.setItem(STORED_FAVORITES, JSON.stringify([incomingFavoriteBarcode]));
    return true;
  }
  const isRemoval = storedFavorites.some((fav) => fav === incomingFavoriteBarcode);
  if (isRemoval) {
    const filteredFav = storedFavorites.filter((fav) => fav !== incomingFavoriteBarcode);
    localStorage.setItem(STORED_FAVORITES, JSON.stringify(filteredFav));
    return false;
  }
  localStorage.setItem(STORED_FAVORITES, JSON.stringify([...storedFavorites, incomingFavoriteBarcode]));
  return true;
};
