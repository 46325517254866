import React from "react";
import ContactForm, { type FormData } from "../components/ContactFrom";
import { ViewTitles } from "../appStrings/views";
import imgSrc from "../assets/viewImages/paperPlane.png";
import "../scss/ContactUs.scss";
import { CompanyDetails } from "../appStrings/company";
// import { sendEmail } from "../api/email";

const ContactUs: React.FC = () => {
  const handleSubmit = (data: FormData) => {
    // await sendEmail(data);
    window.open(`mailto:${CompanyDetails.email}?subject=Hello%20From%20${CompanyDetails.homeUrl}`);
  };

  return (
    <div className="row contact-container">
      <div className="w-100 col contact-left-container d-flex flex-column text-center" >
        <h1 className="view-title">{ViewTitles.ContactUs}</h1>
        <div className="mt-5 centering">
          <ContactForm onSubmit={handleSubmit} />
        </div>
      </div>
      <div className="w-100 col contact-section-container d-flex justify-content-center">
        <img src={imgSrc} className="img-fluid w-100 contact-img" alt={"Please let us know how are we doing"}/>
      </div>
    </div>
  );
};

export default ContactUs;
