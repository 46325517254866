import React, { useEffect, useState } from "react";
import { Divider, Toolbar } from "@mui/material";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";

import Loading from "../components/Loading";
import { MATERIAL_SAFETY_PRODUCT_INFO_CATALOG, type MaterialSafetyProductSectionContent, type MaterialSafetyProductInfo } from "../products/materialSafetyProductInfo";

import "../scss/MaterialSafety.scss";

const MaterialSafety: React.FC = () => {
  const [productDetails, setProductDetails] = useState<MaterialSafetyProductInfo | null>(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const formattedId: number = Number(id);
    const product: MaterialSafetyProductInfo | undefined = MATERIAL_SAFETY_PRODUCT_INFO_CATALOG.find((p) => p.id === formattedId);
    if (product) {
      setProductDetails(product as unknown as MaterialSafetyProductInfo);
    }
    // logEvent(analytics, "material_safety_view", {
    //   barcode: product.barcode,
    //   product_name: product.name,
    //   current_price: product.currentPrice
    // });
    setLoading(false);
  }, [id]);

  if (loading) {
    return (
      <Loading />
    );
  }

  return (
    <Container>
      <div className="d-flex align-items-center justify-content-center material-safety-container bg-light">
        <div className="text-center">
          <h1 className="display-1 fw-bold">Material Safety</h1>
          {productDetails?.sections.map((section, index) => {
            return (
              <div key={index}>
                <div className="material-safety-title-container ">
                  <Toolbar>
                    <span className="material-safety-title" > SECTION {`${section.sectionRoman} - ${section.sectionTitle} `}</span>
                  </Toolbar>
                </div>

                {section.sectionContent.map((sectionContent: MaterialSafetyProductSectionContent, _index: number) => {
                  const { title, content, imageUrl } = sectionContent;
                  return (
                    <div key={_index}>
                      <div className="material-safety-section">
                        <div className="material-safety-section-title-container ">
                          {title && <span className="material-safety-section-title">{title}</span>}
                        </div>
                        {content &&
                          <div className="material-safety-section-content-container">
                            {imageUrl && <img src={imageUrl} alt={title} className="material-safety-section-image" />}
                            <span className="material-safety-section-content-text display-line-break">{content}</span>
                          </div>
                        }
                      </div>
                      <Divider variant="middle"/>
                    </div>
                  );
                })}
              </div>
            );
          })
          }
        </div>
      </div>
    </Container>
  );
};

export default MaterialSafety;
