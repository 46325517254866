/* eslint-disable no-console */
import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

import { THANK_YOU } from "../appStrings/company";
import ButtonStyled from "./ButtonStyled";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": { color: "#fce883" },
      "::placeholder": { color: "#8e2a78" }
    },
    invalid: {
      iconColor: "#ffc7ee",
      color: "#ffc7ee"
    }
  }
};

export default function PaymentForm() {
  const [success, setSuccess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async(e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement
    });

    if (!error && paymentMethod?.id) {
      try {
        const { id } = paymentMethod;
        console.log("🚀 ~ handleSubmit ~ id:", id);
        // const response = await axios.post("http://localhost:4000/payment", {
        //   amount: 1000,
        //   id
        // });

        // if (response?.data.success) {
        //   console.log("Successful payment");
        // }
        setSuccess(true);
      } catch (err) {
        console.log("Error", err);
      }
    } else {
      console.log(error?.message);
    }
  };

  return (
    <>
      {!success
        ? <form onSubmit={handleSubmit}>
          <div className="form-container">
            <fieldset className="FormGroup">
              <div className="FormRow">
                <CardElement options={{ ...CARD_OPTIONS, iconStyle: "solid" }}/>
              </div>
            </fieldset>
            <ButtonStyled>Pay</ButtonStyled>
          </div>
        </form>
        : <div>
          <h2>{THANK_YOU}</h2>
        </div>
      }

    </>
  );
}
