export interface SliderProductType {
  imageName: string
  imageSrc?: string
  description: string
}

export enum ProductTypes {
  oil,
  diffuser
}

export interface Product {
  name: string
  shortDescription: string
  barcode: string
  barcodeWalmart?: string
  barcodeAmazon?: string
  type: ProductTypes
  barcodeImgSrc?: string
  imageSrc?: string
  ingredients?: string
  description: string
  rating?: number
  price?: number
  currentPrice: number
  stock: number
  benefits?: Benefits[]
  bannerSrc: string
  bannerSmallSrc: string
  storeImageSrc?: string
  amazonUrl?: string
  walmartUrl?: string
}

export interface Benefits {
  title?: string
  description?: string
  footer?: string
}

export interface CartItem {
  barcode: string
  quantity: number
}

export interface CheckoutItem extends Product {
  quantity: number
}
