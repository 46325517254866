interface Precaution {
  title: string
  description: string

}

interface Precautions extends Array<Precaution> {}

export const PRECAUTIONS_TITLE: string = "Precautions before use:";

export const PRECAUTIONS: Precautions =
[
  {
    title: "Dilution",
    description: "Essential oils are highly concentrated and potent. Always dilute them with a suitable carrier oil before applying to the skin to reduce the risk of skin irritation or sensitization."
  },
  {
    title: "Patch Test",
    description: "Perform a patch test on a small area of skin before using a new essential oil to check for any allergic reactions or skin sensitivity."
  },
  {
    title: "Avoid Eyes and Mucous Membranes",
    description: "Keep essential oils away from the eyes, ears, and other mucous membranes. If accidental contact occurs, rinse thoroughly with a carrier oil or seek medical attention if necessary."
  },
  {
    title: "Keep Out of Reach of Children",
    description: "Store essential oils in a secure location, out of reach of children and pets. Ingestion of essential oils can be harmful, and some oils are toxic to animals."
  },
  {
    title: "Pregnancy and Nursing",
    description: "Pregnant and nursing women should exercise caution when using essential oils. Some oils are not safe during these times, so it's best to consult a healthcare professional before use."
  },
  {
    title: "Sun Sensitivity",
    description: "Certain citrus essential oils can cause photosensitivity, making the skin more sensitive to sunlight. Avoid direct sunlight or UV rays for at least 12 hours after applying these oils topically."
  },
  {
    title: "Medical Conditions",
    description: "If you have any underlying health conditions or are taking medications, consult a healthcare professional before using essential oils, as some oils may interact with medications or exacerbate certain conditions."
  },
  {
    title: "Storage",
    description: "Store essential oils in dark glass bottles, away from heat, light, and moisture to preserve their potency and extend their shelf life."
  },
  {
    title: "Quality Matters",
    description: "Choose high-quality, pure essential oils from reputable sources to ensure they are free from additives or contaminants."
  },
  {
    title: "Ingestion",
    description: "Ingesting essential oils should only be done under the guidance of a qualified aromatherapist or healthcare professional. Most essential oils should not be ingested without proper knowledge and supervision."
  },
  {
    title: "Keep Away from Open Flames",
    description: "Essential oils are flammable. Keep them away from open flames or heat sources."
  },
  {
    title: "Pets",
    description: "Some essential oils can be toxic to pets. Avoid using essential oils around animals, and if using them for pets, consult a veterinarian for proper guidelines."
  }
];
