/* eslint-disable max-lines */
import bannerPepperMint from "../assets/banners/bannerPeppermint.png";
import bannerPepperMintSmall from "../assets/banners/peppermintSmall.png";
import peppermintPresentation from "../assets/productPresentations/peppermint120ml.png";
import WebPeppermint from "../assets/productPresentations/WebPeppermint.png";

import bannerLavender from "../assets/banners/bannerLavender.png";
import bannerLavenderSmall from "../assets/banners/lavenderSmall.png";
import lavenderPresentation from "../assets/productPresentations/lavender120ml.png";
import WebLavender from "../assets/productPresentations/WebLavender.png";

import teaTreeBanner from "../assets/banners/bannerTeaTree.png";
import teaTreeSmall from "../assets/banners/teaTreeSmall.png";
import teaTreePresentation from "../assets/productPresentations/teaTree120ml.png";
import WebTeaTree from "../assets/productPresentations/WebTeaTree.png";

import { ProductTypes, type Product, type SliderProductType } from "../@types/product.types";

export const LAVENDER_STRING = "Lavender";
export const PEPPER_MINT = "Peppermint";
export const TEA_TREE_STRING = "Tea Tree";
export const ROSE_STRING = "Rose";

export const PRODUCT_CONTAINER_TYPES: SliderProductType[] = [
  {
    imageName: "4oz (120ml)",
    description: "Most popular size"
  },
  {
    imageName: "6.7oz (200ml)",
    description: "Most convenient size"
  },
  {
    imageName: "16.9oz (500ml)",
    description: "most value per price size"
  }
];

export const DIFFUSER_TYPES: SliderProductType[] = [
  {
    imageName: "Commercial size",
    description: "Big diffuser commercial size"
  },
  {
    imageName: "Medium size",
    description: "Medium size"
  },
  {
    imageName: "Medium size",
    description: "Medium size"
  },
  {
    imageName: "Compact size",
    description: "Compact size"
  }
];

// bar codes are represented on U.P.C.
export const PRODUCT_LIST: Product[] = [
  {
    name: LAVENDER_STRING,
    shortDescription: "Lavender Essential Oil 4 fl oz (120ml).",
    barcode: "197644507481",
    barcodeWalmart: "197644507481",
    barcodeAmazon: "198168892138",
    type: ProductTypes.oil,
    bannerSrc: bannerLavender,
    bannerSmallSrc: bannerLavenderSmall,
    description: "Lavender Essential Oil with Therapeutic Grade 100% Pure and Natural, Excellent for Diffusers, Aromatherapy, Body Massage, Topical uses, Home Safe 4 fl oz (120ml).",
    ingredients: "100% Pure Lavender Oil",
    imageSrc: lavenderPresentation,
    storeImageSrc: WebLavender,
    price: 24.99,
    currentPrice: 19.95,
    stock: 10,
    rating: 5,
    amazonUrl: "https://www.amazon.com/dp/B0CY73N1H8",
    walmartUrl: "https://www.walmart.com/ip/Lavender-Essential-Oil-with-Therapeutic-Grade-100-Pure-and-Natural-4-fl-oz-120ml/5273281386",
    benefits: [
      {
        title: "Relaxation",
        description: `
        It has a long history of traditional use for stress, relaxation, and muscle spasms`
      },
      {
        title: "Soothing Burns and Itchiness",
        description: `
          Lavender oil may have an anesthetic effect, similar to capsaicin creams used for pain and burns.
          Studies have shown that it can speed up wound healing and alleviate inflammation and itchiness caused by burns, bug bites, or rashes.`
      },
      {
        title: "Rest Treatment",
        description: `
          Can improve rest quality, especially when used as part of a rest hygiene routine.
          It can help induce relaxation and calm the mind before rest, making it beneficial for individuals who struggle with insomnia.
          It has also shown potential in improving the rest of non healthy persons.`
      },
      {
        title: "Mind fullness",
        description: `
          Research suggests that lavender oil may play a role in preserving cognitive function and reducing symptoms associated with neurological diseases.
          While studies have demonstrated these effects not in humans, further research is needed to confirm its impact on humans.`
      },
      {
        title: "Mood Enhancement",
        description: `
          Lavender oil has been found to have a positive impact on mood, potentially helping combat depression.
          Studies have shown that it can increase overall feelings of well-being and happiness.`
      }, {
        footer: `
        The numerous benefits of lavender oil have been supported by scientific evidence, the versatility and effectiveness of lavender oil in promoting physical and mental well-being.`
      }
    ]
  },
  {
    name: PEPPER_MINT,
    shortDescription: "Peppermint Essential Oil 4 fl oz (120ml).",
    barcode: "197644771493",
    type: ProductTypes.oil,
    bannerSrc: bannerPepperMint,
    bannerSmallSrc: bannerPepperMintSmall,
    description: `
    Peppermint Essential Oil with Therapeutic Grade, for Aromatherapy, Massage and Topical uses, 4 fl oz (120ml).
    `,
    imageSrc: peppermintPresentation,
    storeImageSrc: WebPeppermint,
    currentPrice: 17.95,
    price: 23.99,
    stock: 2,
    rating: 5,
    benefits: [
      {
        title: "Digestive Relief",
        description: `
        Peppermint oil can help alleviate digestive issues such as indigestion, bloating, gas, and stomach discomfort. 
        It relaxes the muscles of the gastrointestinal tract and promotes the flow of bile.`
      },
      {
        title: "Headache and Migraine Relief",
        description: `
        Inhalation or topical application of peppermint oil may provide relief from tension headaches and migraines. 
        Its menthol content has a soothing and cooling effect that can reduce headache symptoms.`
      },
      {
        title: "Respiratory Support",
        description: `
        Peppermint oil has decongestant and expectorant properties, making it effective for easing respiratory issues like sinusitis, bronchitis, and colds. 
        It helps open up the airways and promote easier breathing.`
      },
      {
        title: "Muscle Pain Relief",
        description: `
        Topical application of peppermint oil can provide a cooling sensation and help reduce muscle pain and soreness. 
        It is commonly used in massage oils and balms to soothe tired and achy muscles.`
      },
      {
        title: "Mental Clarity and Alertness",
        description: `
        The aroma of peppermint oil can invigorate the mind, enhancing mental clarity, focus, and alertness. 
        It can be used for increased concentration and productivity.`
      },
      {
        title: "Skin Irritation Soother",
        description: `
        Peppermint oil possesses anti-inflammatory and anti-itch properties that can help soothe skin irritations, including insect bites, rashes, and minor allergic reactions.`
      },
      {
        title: "Antimicrobial Properties",
        description: `
        With its antimicrobial properties, peppermint oil can help fight against certain bacteria and fungi. 
        It finds use in oral hygiene and skin care for its ability to inhibit microbial growth.`
      },
      {
        title: "Natural Insect Repellent",
        description: `
        The strong scent of peppermint oil acts as a natural deterrent for insects like mosquitoes, ants, and spiders. 
        It can be used as a safer alternative to chemical insect repellents.`
      },
      {
        title: "Hair and Scalp Health",
        description: `
        Peppermint oil stimulates the scalp and promotes hair growth. 
        It can also help reduce dandruff and itchiness, making it a popular ingredient in hair care products.`
      },
      {
        title: "Relaxation and Stress Reduction",
        description: `
        The soothing aroma of peppermint oil can promote relaxation and reduce stress and anxiety when used in aromatherapy or diffused in the air.`
      },
      {
        footer: `
        It's important to note that while peppermint oil is highly concentrated, and some individuals may be sensitive to its effects. 
        Always perform a patch test before applying it to a larger area, and consult a healthcare professional or aromatherapist if you have any concerns or specific health conditions. 
        Pregnant or nursing women and young children should also use peppermint oil with caution and under guidance.`
      }

    ]
  },
  {
    name: TEA_TREE_STRING,
    shortDescription: "Tea Tree Essential Oil 4 fl oz (120ml).",
    barcode: "197644295425",
    type: ProductTypes.oil,
    bannerSrc: teaTreeBanner,
    bannerSmallSrc: teaTreeSmall,
    description: `
    Tea Tree Essential Oil with Therapeutic Grade, for Aromatherapy, Massage and Topical uses, 4 fl oz (120ml).
    `,
    imageSrc: teaTreePresentation,
    storeImageSrc: WebTeaTree,
    currentPrice: 17.95,
    price: 23.99,
    stock: 2,
    rating: 5,
    benefits: [
      {
        title: "Antimicrobial Properties",
        description: `
        Tea Tree oil is known for its powerful antimicrobial properties, making it effective in fighting bacteria, viruses, and fungi.
        It can be used to treat skin infections, wounds, and acne.`
      },
      {
        title: "Anti-inflammatory Effects",
        description: `
        The oil has anti-inflammatory properties, which can help reduce redness, swelling, and inflammation in skin conditions like acne, eczema, and psoriasis.        `
      },
      {
        title: "Skin Care",
        description: `
        Tea Tree oil is commonly used in skincare products due to its ability to cleanse and purify the skin.
        It can help unclog pores, control excess oil production, and promote clearer skin.        `
      },
      {
        title: "Relief from Itching and Irritation",
        description: `
        The oil's soothing properties can provide relief from itching and irritation caused by insect bites, rashes, and other skin conditions.`
      },
      {
        title: "Natural Deodorant",
        description: `
        Tea Tree oil's antimicrobial properties make it a popular ingredient in natural deodorants, as it can help control body odor-causing bacteria.`
      },
      {
        title: "Hair Care",
        description: `
        Tea Tree oil is believed to promote a healthy scalp by soothing dryness, itchiness, and dandruff.
        It can be added to shampoos or used in hair treatments.`
      },
      {
        title: "Nail Health",
        description: `
        The oil's antifungal properties can be beneficial in treating nail fungus and promoting healthier nail growth.`
      },
      {
        title: "Respiratory Health",
        description: `
        Tea Tree oil's aroma is often used in aromatherapy to support respiratory health.
        Inhaling the oil can help relieve congestion and ease breathing.`
      },
      {
        title: "Household Cleaning",
        description: `
        Due to its antimicrobial properties, Tea Tree oil is used as a natural disinfectant in household cleaning products.`
      },
      {
        title: "Insect Repellent",
        description: `
        Tea Tree oil's strong scent acts as a natural insect repellent, helping to keep mosquitoes, flies, and other insects away.`
      },
      {
        footer: "It's important to note that while Tea Tree oil has many potential benefits, individual responses may vary, and some people may experience skin irritation or allergic reactions. "
      }
    ]
  }

];

export const PRODUCT_LIST_OILS: Product[] = PRODUCT_LIST.filter(p => p.type === ProductTypes.oil);
