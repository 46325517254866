import React, { useMemo } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { Check2 } from "react-bootstrap-icons";
import { LAVENDER_STRING, PEPPER_MINT, ROSE_STRING, TEA_TREE_STRING } from "../products/product";
import colors from "../scss/Colors.scss";

import { type Benefits } from "../@types/product.types";
import "../scss/Benefits.scss";

interface BenefitsDetailsProps {
  benefits: Benefits[]
  type: string
}

const BenefitsDetails: React.FC<BenefitsDetailsProps> = ({ benefits, type }) => {
  const color: string = useMemo(() => {
    if (type === ROSE_STRING) {
      return colors.rosePrimary;
    } else if (type === PEPPER_MINT) {
      return colors.peppermintPrimary;
    } else if (type === LAVENDER_STRING) {
      return colors.lavenderPrimary;
    } else if (type === TEA_TREE_STRING) {
      return colors.teaPrimary;
    }
    // catch all missing colors
    return "#EABCFB";
  }, [type]);

  return (
    <div className="benefits-container" style={{ backgroundColor: color }}>
      <span className="text-title-white">
        {type} benefits
      </span>
      <div className="sub-container " >
        {benefits.map((benefit, i) => {
          return (
            <div key={i}>
              {
                benefit?.title &&
                <Accordion className="my-1" key={i} TransitionProps={{ unmountOnExit: true }}>
                  <AccordionSummary>
                    <span className="text-title-black-2">
                      <Check2 size={25} className="me-3" color="#26e7b0" />
                      {benefit.title}
                    </span>
                  </AccordionSummary>
                  <AccordionDetails>
                    <span className="text">
                      {benefit.description}
                    </span>
                  </AccordionDetails>
                </Accordion>
              }
              {benefit?.footer && <span className="text display-line-break">
                {benefit.footer}
              </span>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default BenefitsDetails;
