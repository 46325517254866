import React from "react";

import AppStateProvider from "./context/AppState";
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";
import Navigator from "./navigation/Navigator";
import TopHeader from "./components/TopHeader";
import Toast from "./components/Toast";
import ToastContextProvider from "./context/ToastContext";
import { BANNER_END_TITLE, BANNER_TITLE, SUCCESS } from "./appStrings/strings";

import "./firebase/config";
import "./scss/App.scss";
import "./scss/Common.scss";

const App: React.FC = () => {
  return (
    <AppStateProvider>
      <ToastContextProvider>
        <Toast title={SUCCESS} bodyText={BANNER_TITLE} bodyTextStrong={BANNER_END_TITLE}/>
        <div className="container-fluid main-container">
          <TopHeader />
          <NavBar />
          <Navigator />
          <Footer />
        </div>
      </ToastContextProvider>
    </AppStateProvider>
  );
};

export default App;
