import React from "react";
import imageSrc from "../assets/containerImages/lavenderFiled.png";
import titleSrc from "../assets/viewImages/aboutUs.png";
import {
  ABOUT_FOOTER,
  ABOUT_FOOTER_2,
  BEHIND,
  BEHIND_TITLE,
  CS,
  CS_TITLE,
  JOIN_US,
  JOIN_US_TITLE,
  MISSION,
  MISSION_TITLE,
  QA,
  QA_TITLE,
  THANK_YOU,
  WELCOME
} from "../appStrings/company";

import "../scss/About.scss";
import "../scss/Common.scss";

export default function About() {
  return (
    <div className="background-dark-diluted ">
      <div className="about-content">
        <div className="centering about-title-image">
          <img src={titleSrc} className="title-image" alt={"Lavender Field"} />
        </div>
        <div className="text-container">
          <span className="display-line-break text-light">
            {WELCOME}
            <span className="text-title-light">
              {MISSION_TITLE}
            </span>
            {MISSION}
            <span className="text-title-light">
              {QA_TITLE}
            </span>
            {QA}
            <span className="text-title-light">
              {BEHIND_TITLE}
            </span>
            {BEHIND}
            <span className="text-title-light">
              {CS_TITLE}
            </span>
            {CS}
            <span className="text-title-light">
              {JOIN_US_TITLE}
            </span>
            {JOIN_US}
            {THANK_YOU}
            {ABOUT_FOOTER}
            {ABOUT_FOOTER_2}
          </span>
        </div>

      </div>
      <img src={imageSrc} className="img-fluid w-100" alt={"Lavender Field"} />
    </div>
  );
}
