import PropTypes from "prop-types";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

interface ToastContextType {
  showToast: boolean
  toastText: string
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>
}

export const ToastContext = createContext<ToastContextType>({
  showToast: false,
  toastText: "",
  setShowToast: () => {}
});

const ToastContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const [showToast, setShowToast] = useState(false);
  const [toastText, setToastText] = useState("");

  const showToastWithText = useCallback((text: string) => {
    setToastText(text);
    setShowToast(true);
  }, []);

  const contextValues = useMemo(() => ({
    showToast,
    toastText,
    setShowToast,
    showToastWithText
  }), [showToast, showToastWithText, toastText]);

  return (
    <ToastContext.Provider value={ contextValues }>
      { children }
    </ToastContext.Provider>
  );
};

ToastContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export const useToastContext = () => useContext(ToastContext);
export default ToastContextProvider;
