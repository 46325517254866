import React from "react";
import amazonImageSrc from "../assets/brands/amazonBuyNow.png";
import walmartImageSrc from "../assets/brands/walmart.png";
import "../scss/StoreButton.scss";

export enum StoreButtonTypes {
  walmart = "walmart",
  amazon = "amazon",
}

interface StoreButtonProps {
  url: string
  type: StoreButtonTypes
}

const StoreButton: React.FC<StoreButtonProps> = ({ url, type }) => {
  if (type === StoreButtonTypes.walmart) {
    return (
      <div className="walmart-button-container">
        <a href={url} target="_blank" rel="noreferrer">
          <img src={walmartImageSrc} className="walmart-button-image"/>
        </a>
      </div>
    );
  }

  return (
    <div className="centering">
      <a href={url} target="_blank" rel="noreferrer">
        <img src={amazonImageSrc} className="amazon-button-image"/>
      </a>
    </div>
  );
};

export default StoreButton;
