import React from "react";
import { Link } from "react-router-dom";

import Rating from "../components/Rating";
import PriceAndDiscount from "../components/PriceAndDiscount";
import missingImage from "../assets/common/missingImage.png";

import { type Product } from "../@types/product.types";
import "../scss/ProductList.scss";

interface SingleProductProps {
  item: Product
  key: string | number
  useRow?: boolean
}

const SingleProduct: React.FC<SingleProductProps> = ({ item, key = 0, useRow }) => {
  if (useRow) {
    return (
      <Link key={key} className="col-3 item-container-list border" to={`/productDetails/${item.barcode}`} >
        <div className="centering flex-row item-sub-container-list">
          <div className="">
            <img src={item.imageSrc ?? missingImage} className="image-list img-fluid"/>
            <span className="text-title-black-2">{item?.shortDescription ?? item.name}</span>
          </div>
          <div>
            <span className="text-small">{item.description}</span>
            <PriceAndDiscount onlyPrice currentPrice={item.currentPrice} price={item.price}/>
          </div>
        </div>
      </Link>
    );
  }
  return (
    <Link key={key} className="col-3 item-container-list border" to={`/productDetails/${item.barcode}`} >
      <div className="centering flex-column item-sub-container-list">
        <img src={item.imageSrc ?? missingImage} className="image-list img-fluid"/>
        <span className="text-title-black-2">{item.name}</span>
        <span className="text-small">{item.description}</span>
        <PriceAndDiscount onlyPrice currentPrice={item.currentPrice} price={item.price}/>
        <Rating count={item.rating}/>
      </div>
    </Link>
  );
};

export default SingleProduct;
