import React, { useMemo } from "react";
import Card from "react-bootstrap/Card";

interface PADProps {
  price: number | undefined
  currentPrice: number
  onlyPrice?: boolean
}

const PriceAndDiscount: React.FC<PADProps> = ({ price, currentPrice, onlyPrice }) => {
  const minimumPrice = useMemo(() =>
    (price ?? 0) > currentPrice ? price : currentPrice, [currentPrice, price]);
  if (onlyPrice) {
    return (
      <div>
        <Card.Text className="mb-0 fs-2">${currentPrice}</Card.Text>
      </div>
    );
  } else if ((currentPrice && price) && (currentPrice < price)) {
    return (
      <div>
        <span className="text-strike">${price}</span>
        <Card.Text className="mb-0 fs-2">${currentPrice}</Card.Text>
      </div>
    );
  } else if (((!currentPrice && price) ?? !((price ?? 0) > currentPrice)) && minimumPrice) {
    return (
      <div>
        <Card.Text className="mb-0 fs-2">${minimumPrice}</Card.Text>
      </div>
    );
  }
  return null;
};

export default PriceAndDiscount;
