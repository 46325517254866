export enum CompanyDetails {
  name = "Gadega",
  email = "info@gadega.com",
  phone = "801-980-0228",
  instagram_url = "https://www.instagram.com/gadega.us/",
  facebook_url = "https://www.facebook.com/gadega.us",
  homeUrl = "gadega.com",
}

export enum ProductCategories {
  // Diffusers = "Diffusers",
  EssentialOils = "Essential Oils",
}

// ABOUT
export const WELCOME: string = `Welcome to ${CompanyDetails.name}, an emerging e-commerce company passionate about well-being.\n 
Based in the picturesque state of Utah, our company was founded in 2023. While we may be small in size, our dedication to customer trust, by providing top-tier premium quality sets us apart.\n\n`;
export const MISSION_TITLE: string = "Our Mission: \n";
export const MISSION: string = "With this we believe in the transformative potential of the uplift the spirit, helping individuals radiate confidence from within.\n\n";

export const QA_TITLE: string = "Quality Assurance: \n";
export const QA: string = "Purity and quality are of utmost importance, to ensure that you receive nothing but the best, we prioritize sustainability and ethical practices, supporting suppliers who share our commitment to the environment.\n\n";

export const BEHIND_TITLE: string = `The Passion Behind ${CompanyDetails.name}: \n`;
export const BEHIND: string = "We are a team of enthusiasts who genuinely believe in every product we offer is top quality, because is carefully selected based on its purity.\n\n";

export const CS_TITLE: string = "Customer Experience: \n";
export const CS: string = "As a small company, we take pride in providing personalized attention to each and every customer. Your satisfaction and trust is our top priority. We strive to create an exceptional experience from the moment you buy your first order.\n\n";

export const JOIN_US_TITLE: string = "Join Us: \n";
export const JOIN_US: string = "Whether you're an experienced aromatherapy enthusiast or a curious beginner, we have something to suit your needs. Take a moment to immerse yourself in the captivating scents and allow nature's beauty to enrich your life.\n\n";

export const THANK_YOU: string = `Thank you for considering ${CompanyDetails.name} as your trusted source for natural wellness solutions. We look forward to serving you and being a part of your journey to radiant health and vitality.\n\n`;

export const ABOUT_FOOTER: string = "Your trusted source.\n\n";
export const ABOUT_FOOTER_2: string = `${CompanyDetails.name} - Enhancing Lives Through Nature's Essences.\n`;
