/* eslint-disable max-lines */

import nfpaSrc from "../assets/logos/MSDS/NFPA.png";
import hmisSrc from "../assets/logos/MSDS/HMIS.png";
export interface MaterialSafetyProductSectionContent {
  title: string
  content?: string
  imageUrl?: string
}

export interface MaterialSafetyProductSection {
  id: number
  sectionRoman: string
  sectionTitle: string
  sectionContent: MaterialSafetyProductSectionContent[]
}
export interface MaterialSafetyProductInfo {
  id: number
  sections: MaterialSafetyProductSection[]
}

export type MaterialSafetyProductCatalog = MaterialSafetyProductInfo[];

export const MATERIAL_SAFETY_PRODUCT_INFO_CATALOG: MaterialSafetyProductCatalog =
  [{
    id: 197644507481,
    sections: [
      {
        id: 1,
        sectionRoman: "I",
        sectionTitle: "IDENTIFICATION OF THE SUBSTANCE / PREPARATION & THE COMPANY",
        sectionContent: [
          {
            title: "Chemical / Trade(identity used on label)",
            content: "Lavender Oil"
          },
          {
            title: "Manufacturer's Name",
            content: "Gadega"
          },
          {
            title: "Address",
            content: "Gadega LLC, 2313 Sunrise Ranch Dr 84664, Mapleton, Utah, USA. \n Phone: +1 801-980-0228. \n Email: info@gadega.com"
          }
        ]
      },
      {
        id: 2,
        sectionRoman: "II",
        sectionTitle: "COMPOSITION / INFORMATION ON INGREDIENTS",
        sectionContent: [
          {
            title: "Chemical characterization:",
            content: "CAS No. 8000-28-0 \n Description: Lavender essential oil \n EINECS Number: 289-995-2"
          },
          {
            title: "Additional information:",
            content: "Botanical name/INCI name: Lavandula angustifolia Mill. \n Part of the plant: Flowers \n Status: Natural 23-24/25 \n FEMA: 2622 \n Harmonize No: 3301.29.61.00.09C"
          }
        ]
      },
      {
        id: 3,
        sectionRoman: "III",
        sectionTitle: "HAZARDS IDENTIFICATION",
        sectionContent: [
          {
            title: "Hazard description:",
            content: "Not Aplicable."
          },
          {
            title: "Information pertaining to particular dangers for man and environment:",
            content: "The product has to be labeled due to the calculation procedure of international guideline. The classification was made according to the latest editions of international substances lists,and expanded upon from company and literature data."
          },
          {
            title: "NFPA ratings ( scale 0 – 4 )",
            content: "Health = 0\n Fire = 0\n Reactivity = 0",
            imageUrl: nfpaSrc
          },
          {
            title: "HMIS-ratings ( scale 0 - 4 )",
            content: "Health = 0\n Fire = 0\n Reactivity = 0",
            imageUrl: hmisSrc
          }
        ]
      },
      {
        id: 4,
        sectionRoman: "IV",
        sectionTitle: "FIRST-AID MEASURES",
        sectionContent: [
          {
            title: "EYE CONTACT:",
            content: "Rinse immediately with plenty of water for 10 Minutes at least Contact a physician if symptoms Persist."
          },
          {
            title: "SKIN CONTACT:",
            content: "Wash thoroughly with soap and water,flush with Plenty of water. Take off spoilt clothes. Contact Physician if symptoms persist."
          },
          {
            title: "INGESTION:",
            content: "Wash the mouth with water, seek medical advice Immediately."
          },
          {
            title: "INHALATION:",
            content: "Remove from exposure site to fresh air and keep at rest. Obtain medical advice."
          }
        ]
      },
      {
        id: 5,
        sectionRoman: "V",
        sectionTitle: "FIRE-FIGHTING MEASURES",
        sectionContent: [
          {
            title: "EXTINGUISHING MEDIA:",
            content: "Carbon Dioxide, dry chemical or foam. Closed containers may build up pressure at elevated Temperature."
          },
          {
            title: "SPECIAL PROCEDURES:",
            content: "Determine the need to evacuate or isolate the area according to your local emergency plan."
          },
          {
            title: "FIREFIGHTING HAZARDS:",
            content: "None identified."
          },
          {
            title: "PROTECTIVE EQUIPMENT:",
            content: "Self-contained breathing apparatus and protective clothing should be worn when fighting fires Involving essential oils or chemicals."
          },
          {
            title: "COMBUSTION PRODUCT:",
            content: "Carbon Monoxide, Carbon Dioxide"
          },
          {
            title: "EXTINGUISHING MEDIA:",
            content: "CO2, foam, dry chemicals."
          },
          {
            title: "RECOMMENDED:",
            content: "As directed in the equipment user manual."
          },
          {
            title: "NON-RECOMMENDED:",
            content: "Do not use direct water jet on burning material."
          },
          {
            title: "EXTINGUISHING PROCEDURES:",
            content: "Closed containers may build up pressure at elevated temperatures. If possible, containers should be closed with a water spray"
          }
        ]
      },
      {
        id: 6,
        sectionRoman: "VI",
        sectionTitle: "ACCIDENTAL RELEASE MEASURES",
        sectionContent: [
          {
            title: "PERSONAL PRECAUTIONS:",
            content: "Use an individual protective equipment (waterproof boots, suitable protective clothing, safety glasses). Prevent any contact with hot surfaces. Do not approach facing the wind."
          },
          {
            title: "ENVIRONMENT:",
            content: "Keep away from drains, surface and groundwater and soil."
          },
          {
            title: "MEASURES FOR CLEANING / COLLECTING:",
            content: "Absorb with liquid- binding material (sand, diatomite, acid binders, universal binders, sawdust). Dispose contaminated material as waste according to item 13."
          }
        ]
      },
      {
        id: 7,
        sectionRoman: "VII",
        sectionTitle: "HANDLING AND STORAGE",
        sectionContent: [
          {
            title: "PRECAUTIONS IN HANDLING:",
            content: "Apply according to good manufacturing and industrial hygiene practices with proper ventilation. Do not drink, eat or smoke while handling. Respect good personal hygiene."
          },
          {
            title: "STORAGE CONDITIONS:",
            content: "Store in cool, dry and ventilated area away from foodstuffs. Tightly closed original container. Keep air contact to a minimum."
          },
          {
            title: "FIRE PROTECTION:",
            content: "Keep away from ignition sources and naked flame."
          }
        ]
      },
      {
        id: 8,
        sectionRoman: "VIII",
        sectionTitle: "EXPOSURE CONTROLS / PERSONAL PROTECTION",
        sectionContent: [
          {
            title: "EXPOSURE CONTROLS:",
            content: "Additional information about design of technical facilities: No further data; see item 7. Ingredients with limit values that require monitoring at the workplace: Not required. Additional information: The lists valid during the making were used as basis."
          },
          {
            title: "PERSONAL PROTECTIVE EQUIPMENT:",
            content: "General protective and hygienic measures: Keep away from foodstuffs, beverages and feed. Wash hands before breaks and at the end of work. Respiratory protection: Not required."
          },
          {
            title: "PROTECTION OF HANDS:",
            content: `A. Material of gloves:
            The selection of the suitable gloves does not only depend on the material, but also on further marks of quality and varies from manufacturer to manufacturer. 
            The glove material has to be impermeable and resistant to the product/ the substance/ the preparation. 
            Due to missing tests no recommendation to the glove material can be given for the product/ the preparation/ the chemical mixture. 
            Selection of the glove material on consideration of the penetration times, rates of diffusion and the degradation. 
            B.Penetration time of glove material:
            The exact break trough time has to be found out by the manufacturer of the protective gloves and has to be observed.`
          },
          {
            title: "EYE PROTECTION:",
            content: "Tightly sealed goggles."
          }
        ]
      },
      {
        id: 9,
        sectionRoman: "IX",
        sectionTitle: "PHYSICAL AND CHEMICAL PROPERTIES",
        sectionContent: [
          {
            title: "ODOUR:",
            content: "Lavender Herbal Floral Odor"
          },
          {
            title: "COLOR:",
            content: "Yellow"
          },
          {
            title: "APPEARANCE:",
            content: "Oily liquid"
          },
          {
            title: "FLASH POINT:",
            content: "220 °F"
          },
          {
            title: "BOILING POINT :",
            content: "224 °F"
          },
          {
            title: "MELTING POINT:",
            content: ""
          },
          {
            title: "SOLUBILITY:",
            content: "Soluble in alcohol, insoluble in water"
          },
          {
            title: "SPECIFIC GRAVITY AT 20°C",
            content: "0.8820 – 0.8950"
          },
          {
            title: "REFRACTIVE INDEX AT 20°C",
            content: "1.4595 – 1.4940"
          },
          {
            title: "OPTICAL ROTATION AT 20°C",
            content: "(-8.9’)"
          }
        ]
      },
      {
        id: 10,
        sectionRoman: "X",
        sectionTitle: "STABILITY AND REACTIVITY",
        sectionContent: [
          {
            title: "REACTIVITY:",
            content: `A1. It presents no significant reactivity hazards by itself or in contact with water.Avoid contact with strong acids, alkali or oxidizing agents. 
            A2. No decomposition if used according to specifications. 
            A3. Dangerous reactions No dangerous reactions known. 
            A4. Dangerous decomposition products: No dangerous decomposition products known.`
          },
          {
            title: "DECOMPOSITION:",
            content: `B1. Carbon monoxide and unidentified organic compounds may be formed during combustion. 
            B2. Shelf life: 36 months if stored in recommended conditions, after this period, the product can be used after control.`
          }
        ]
      },
      {
        id: 11,
        sectionRoman: "XI",
        sectionTitle: "TOXICOLOGICAL DATA",
        sectionContent: [
          {
            title: "Consult the Toxicological Institute of your Country. The material is Natural Oils and Non- Toxic."
          }
        ]
      },
      {
        id: 12,
        sectionRoman: "XII",
        sectionTitle: "ECOLOGICAL INFORMATION",
        sectionContent: [
          {
            title: "BIODEGRADABILITY:",
            content: "Not determined"
          },
          {
            title: "PRECAUTIONS:",
            content: "Prevent contamination of soil, ground and surface water."
          }
        ]
      },
      {
        id: 13,
        sectionRoman: "XIII",
        sectionTitle: "DISPOSAL CONSIDERATIONS",
        sectionContent: [
          {
            title: "PRODUCT RECOMMENDATION:",
            content: `Smaller quantities can be disposed of with household waste. 
            Avoid disposing into drainage Systems and into the environment.
            Disposal must be made according to official regulations.`
          },
          {
            title: "UNCLEANED PACKAGING:",
            content: "Recommendation: Disposal must be made according to official regulations."
          }
        ]
      },
      {
        id: 14,
        sectionRoman: "XIV",
        sectionTitle: "TRANSPORTATION DATA",
        sectionContent: [
          {
            title: "All the materials are properly sealed and tightly packed in Aluminum bottles and GI drums and NOT RESTRICTED IN TRANSIT THROUGH AIR. LAND AND SEA”."
          }
        ]
      },
      {
        id: 15,
        sectionRoman: "XV",
        sectionTitle: "REGULATORY INFORMATION",
        sectionContent: [
          {
            title: "LAW INFORMATION:",
            content: `<<Technical instructions for the Safe Transport of Dangerous Goods >> 
            <<Dangerous Goods Regulation >>(IATA) 
            <<International Maritime Dangerous Goods >>`
          },
          {
            title: "OSHA HAZARDS"
          },
          {
            title: "TSCA STATUS"
          },
          {
            title: "DSL STATUS"
          },
          {
            title: "SARA 302/311/312/313"
          },
          {
            title: "IN ACCORDANCE WITH ALL FEDERAL, STATE AND LOCAL LAWS"
          },
          {
            title: "PRODUCT RELATED HAZARD INFORMATION:",
            content: "The product has not been classified and marked in accordance respective national laws. Observe the general safety regulations when handling chemicals."
          }
        ]
      },
      {
        id: 16,
        sectionRoman: "XVI",
        sectionTitle: "OTHER INFORMATION",
        sectionContent: [
          {
            title: "The information in this MSDS/SDS was obtained from sources which we believe are reliable. However, the information is provided without any warranty, express or implied, regarding its correctness. The conditions or methods of handling, storage, use or disposal of the product are beyond our control and may be beyond our knowledge. For this and other reasons, we do not assume responsibility and expressly disclaim liability for loss, damage or expense arising out of or in anyway connected with the handling, storage, use or disposal of the product. This MSDS/SDS was prepared and is to be used only for this product. If the product is used as a component in another product, this MSDS/SDS information may not be applicable."
          }
        ]
      }

    ]
  }];
