import { CompanyDetails } from "./company";

export const LOGO_ALT: string = `${CompanyDetails.name} Logo.`;
export const ACKNOWLEDGEMENTS_AND_ATTRIBUTIONS = "Acknowledgements and attributions";
export enum Acknowledgements {
  RawPixel = "Images - (RawPixel)",
  MaterialUI = "Styles, code - (Material UI)",
  Bootstrap = "Styles, code - (Bootstrap)",
  FramerMotion = "Styles, code - (Framer Motion)",
  Photopea = "Edition Tool - (Photopea)",
}

export enum AcknowledgementsUrls {
  RawPixel = "https://www.rawpixel.com/",
  MaterialUI = "https://mui.com/",
  Bootstrap = "https://react-bootstrap.netlify.app/",
  Photopea = "https://www.photopea.com/",
  FramerMotion = "https://www.framer.com/motion/",
}

export const OOPS: string = "Opps!";
export const PAGE_NOT_FOUND: string = "Page not found.!";
export const PAGE_NOT_EXIST: string = "The page you’re looking for doesn’t exist.";

export const CAUTIONS: string = "Potential skin sensitivity. Keep away from children. If you are pregnant, nursing, or receiving medical treatment, consult your doctor before using this or any other product. Avoid touching eyes, inner ears, and delicate areas.";
export const USAGE: string = "Usage";
export const INGREDIENTS: string = "Ingredients";
export const USAGE_DIRECTIONS: string = `For aromatic use, place three to four drops in a diffuser of your preference.
    For topical use gently apply one to two drops to the desired area, for those with sensitive skin, dilute with a carrier oil before use. 
    Please always review the additional precautions before use.`;
export const CLICK_HERE: string = "Click here";
export const FRASE_MAMADORA: string = "While there are no guarantees for a happy ending, lets make a pleasant journey - MG";

export const EMPTY_CART: string = "Your shopping cart is empty.";
export const EMPTY_CART_SUBTITLE: string = "Explore our shopping center to find more amazing products and continue your shopping journey.";

export const EMPTY_FAVORITES: string = "You don't have favorites, Yet.";
export const EMPTY_FAVORITES_SUBTITLE: string = "Explore our shopping center to find more amazing products.";

export const OUT_OF_STOCK: string = "Out of Stock!";
export const SOLD_OUT: string = "Sold Out!";
export const ESSENTIAL_OIL_USES: string = "Multiple ways to use Essential oil:";

export const SUCCESS: string = "Success";
export const BANNER_TITLE: string = "$45 or more to";
export const BANNER_END_TITLE: string = "Free Ship to Home!";
export const ADD_TO_CART: string = "Add To Cart";
export const AMAZON: string = "Amazon";
