import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";
import { ArrowRightCircleFill, ArrowLeftCircleFill } from "react-bootstrap-icons";
import { PRODUCT_LIST } from "../products/product";
import "../scss/MainSlider.scss";

const MainSlider: React.FC = () => {
  const NextIcon = <ArrowRightCircleFill size={40} />;
  const PrevIcon = <ArrowLeftCircleFill size={40} />;

  return (
    <Carousel controls className="py-4" nextIcon={NextIcon} prevIcon={PrevIcon}>
      {PRODUCT_LIST.map(({ bannerSrc, bannerSmallSrc, barcode, name }) => (
        <Carousel.Item key={barcode}>
          <Link to={`/productDetails/${barcode}`}>
            {/* hide on small or medium sizes */}
            <img src={bannerSrc} className="img-fluid w-100 d-md-none d-lg-block d-sm-none d-md-block d-none d-sm-block" alt={name}/>
            {/* show only on small or medium sizes 768px or higher */}
            <img src={bannerSmallSrc} className="img-fluid w-100 d-lg-none" alt={name}/>
          </Link>
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default MainSlider;
