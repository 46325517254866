import React from "react";
import videoSrc from "../assets/gif/backgroundLavenderField.gif";

import "../scss/BackgroundGif.scss";

const BackgroundGif: React.FC = () => {
  return (
    <img src={videoSrc} className="bannerGif"/>
  );
};

export default BackgroundGif;
