import React from "react";

import BackgroundGif from "../components/BackgroundGif";
import MainSlider from "../components/MainSlider";
import imageMain from "../assets/store/Store-Main.png";
import imageUses from "../assets/store/Store-Usage.png";
import ProductList from "../components/ProductList";
import { PRODUCT_LIST_OILS } from "../products/product";
import { ESSENTIAL_OIL_USES } from "../appStrings/strings";

import "../scss/Home.scss";

const Home: React.FC = () => {
  return (
    <div>
      <div className="home-container">
        <MainSlider />
        <div className="centering">
          <img src={imageMain} className="img-fluid home-main-img m-5" alt={"Welcome Image"} />
        </div>
        <div className="bg-light w-100 p-4 ">
          <div className="flex-column centering bg-light">
            <ProductList list={PRODUCT_LIST_OILS}/>
          </div>
        </div>
      </div>
      <div className="d-flex flex-column centering my-4">
        <span className="text-title-white m-4">{ESSENTIAL_OIL_USES}</span>
        <img src={imageUses} className="img-fluid" alt={"Lavender Field"} />
      </div>
      <BackgroundGif />
    </div>
  );
};

export default Home;
