import React, { useState, useEffect, useMemo } from "react";
import Card from "react-bootstrap/Card";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { logEvent } from "firebase/analytics";
import { ButtonGroup, Container } from "react-bootstrap";
import { useParams, Link } from "react-router-dom";
import { TextField } from "@mui/material";

import PageNotFound from "./PageNotFound";
import Rating from "../components/Rating";
import ButtonStyled from "../components/ButtonStyled";
import BenefitsDetails from "../components/BenefitsDetails";
import PriceAndDiscount from "../components/PriceAndDiscount";
import Loading from "../components/Loading";
import StoreButton, { StoreButtonTypes } from "../components/StoreButton";
import OutOfStock from "../components/OutOfStock";
import { analytics } from "../firebase/config";
import { PRODUCT_LIST } from "../products/product";
import { ADD_TO_CART, CLICK_HERE, INGREDIENTS, USAGE, USAGE_DIRECTIONS } from "../appStrings/strings";
import { ViewPaths } from "../appStrings/views";
import { getFavorites, storeOrRemoveFavorite } from "../controllers/favorites.controller";
import { addToCart } from "../controllers/shoppingCart.controller";

import { valueTypes } from "../@types/values.types";
import { type Product } from "../@types/product.types";
import "../scss/ProductDetails.scss";

const ProductDetails: React.FC = () => {
  const [productDetails, setProductDetails] = useState<Product | undefined>(undefined);
  const [quantity, setQuantity] = useState<number>(1);
  const [isFavorite, setIsFavorite] = useState<boolean>(false);
  const outOfStock = useMemo(() => !(productDetails?.stock ?? 0 < 1), [productDetails?.stock]);
  const soldOut = true; // TODO: implement firebase function to check if product is sold out
  const [loading, setLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    const favorites = getFavorites();
    if (favorites?.some((barcode) => barcode === id)) {
      setIsFavorite(true);
    }
  }, [id]);

  useEffect(() => {
    const product: Product | undefined = PRODUCT_LIST.find(p => p.barcode === id);
    if (product?.barcode !== undefined) {
      setProductDetails(product);
      logEvent(analytics, "product_details_view", {
        barcode: product.barcode,
        product_name: product.name,
        current_price: product.currentPrice
      });
    }
    setLoading(false);
  }, [id]);

  const handleFavoriteClick = () => {
    if (productDetails?.barcode) {
      const newValue: boolean = storeOrRemoveFavorite(productDetails.barcode);
      setIsFavorite(newValue);
    }
  };
  const handleOnQuantityChange = ({ target }: any) => {
    const incomingQuantity = Number(target?.value || 1);
    // eslint-disable-next-line valid-typeof
    if (typeof incomingQuantity === valueTypes.number && incomingQuantity > 0) {
      setQuantity(incomingQuantity);
    }
  };

  if (loading) {
    return (
      <Loading />
    );
  }

  if (!productDetails?.barcode) {
    return <PageNotFound />;
  }

  return (
    <div>
      <Container className="my-3">
        <Card className="border-0 position-relative flex-row row centering">
          {productDetails?.imageSrc &&
            <div className="col-8 w-auto mx-3">
              <img src={productDetails.imageSrc} className="img-fluid product-details-image" alt={productDetails.description} />
            </div>
          }
          <Card.Body className="text-left pt-2 col-4 shrink-section">
            <Card.Title className="fs-2">{productDetails.name}</Card.Title>
            <Rating count={productDetails.rating}/>
            <hr></hr>
            <PriceAndDiscount price={productDetails.price} currentPrice={productDetails.currentPrice}/>
            <Card.Text className="fs-4">{productDetails.description}</Card.Text>
            <Card.Text className="mb-0 fs-2">{INGREDIENTS}</Card.Text>
            <Card.Text className="fs-4">{productDetails.ingredients}</Card.Text>
            <Card.Text className="mb-0 fs-2">{USAGE}</Card.Text>
            <Card.Text className="fs-4 display-line-break">
              {USAGE_DIRECTIONS + " "}
              <Link to={"/" + ViewPaths.Precautions} className="fs-5">{CLICK_HERE}</Link>
            </Card.Text>
            <ButtonGroup className="d-flex align-items-center">
              <ButtonStyled onClick={handleFavoriteClick}>
                {isFavorite
                  ? <FavoriteIcon htmlColor="red"/>
                  : <FavoriteBorderIcon htmlColor="red"/>}
              </ButtonStyled>
              {outOfStock || soldOut
                ? <OutOfStock isSoldOut/>
                : <>
                  <ButtonStyled
                    disabled={outOfStock || soldOut}
                    onClick={() => { addToCart({ barcode: productDetails?.barcode, quantity }); } }
                  >
                    <span className="item-add-cart-text">
                      {ADD_TO_CART}
                    </span>
                  </ButtonStyled>
                  <TextField
                    type="number"
                    onChange={handleOnQuantityChange}
                    value={quantity}
                    InputProps={{ style: { fontSize: 16, width: 100 } }}
                  />
                </>
              }
            </ButtonGroup>
            <div className="store-buttons-container">
              {productDetails?.amazonUrl && <StoreButton url={productDetails.amazonUrl} type={StoreButtonTypes.amazon}/>}
              {productDetails?.walmartUrl && <StoreButton url={productDetails.walmartUrl} type={StoreButtonTypes.walmart}/>}
            </div>
            {/* <ButtonStyled // for testing only
              onClick={ clearCart}
            >
              CLEAR
            </ButtonStyled> */}
          </Card.Body>
          {
            productDetails?.benefits?.length && <BenefitsDetails benefits={productDetails?.benefits} type={productDetails.name}/>
          }
        </Card>
      </Container>
    </div>
  );
};

export default ProductDetails;
