import React, { useEffect, useState } from "react";

import ProductList from "../components/ProductList";
import emptyFavSrc from "../assets/viewImages/heartFlying.png";
import { getFavorites } from "../controllers/favorites.controller";
import { PRODUCT_LIST } from "../products/product";
import { type Product } from "../@types/product.types";
import { ViewTitles } from "../appStrings/views";
import { EMPTY_FAVORITES, EMPTY_FAVORITES_SUBTITLE } from "../appStrings/strings";
import "../scss/Favorites.scss";
const Favorites: React.FC = () => {
  const [favoriteList, setFavoriteList] = useState<Product[]>([]);

  useEffect(() => {
    const favoriteIndexes = getFavorites();
    if (favoriteIndexes) {
      const commonElements: Product[] = PRODUCT_LIST.filter((item) => favoriteIndexes.includes(item.barcode));
      setFavoriteList(commonElements);
    }
  }, []);

  if (!favoriteList.length) {
    return (
      <div className="row fav-container">
        <div className="w-100 col fav-left-container d-flex flex-column text-center" >
          <h1 className="text-title-black-xl">{ViewTitles.Favorites}</h1>
          <h1 className="fs-2">{EMPTY_FAVORITES}</h1>
          <h1 className="fs-4">{EMPTY_FAVORITES_SUBTITLE}</h1>
        </div>
        <div className="w-100 col fav-right-container d-flex justify-content-center">
          <img src={emptyFavSrc} className="img-fluid w-100 fav-img" alt={"Working hard or hardly working?"}/>
        </div>
      </div>
    );
  }

  return (
    <div className="container bg-light w-100 p-4 ">
      <div className="row centering">
        <ProductList list={favoriteList}/>
      </div>
    </div>
  );
};

export default Favorites;
