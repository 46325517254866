import React, { useEffect, useState } from "react";
import emptyCart from "../assets/viewImages/cart.png";
import { ViewTitles } from "../appStrings/views";
import { EMPTY_CART, EMPTY_CART_SUBTITLE } from "../appStrings/strings";
import { type Product, type CartItem, type CheckoutItem } from "../@types/product.types";
import { STORAGE_ADD_TO_CART } from "../appStrings/storage.strings";
import "../scss/ShoppingCart.scss";
import { updateCart } from "../controllers/shoppingCart.controller";
import { PRODUCT_LIST } from "../products/product";
import Loading from "../components/Loading";
import SingleProduct from "../components/SingleProduct";
import ButtonStyled from "../components/ButtonStyled";
// import { Link } from "react-router-dom";

const ShoppingCart = () => {
  const [cartItems, setCartItems] = useState<CheckoutItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedCart: string | null = sessionStorage.getItem(STORAGE_ADD_TO_CART);
    if (storedCart) {
      const cartItemsData: CartItem[] = JSON.parse(storedCart);
      const formattedCartItems: CheckoutItem[] = cartItemsData.map((item) => {
        const product: Product = PRODUCT_LIST.find(p => p.barcode === item.barcode) as Product;
        return { ...product, quantity: item.quantity };
      });
      setCartItems(formattedCartItems);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  if (cartItems.length > 0) {
    return (
      <div className="row cart-container">
        <h1>Shopping Cart</h1>
        <ul>
          {cartItems.map((item: Product, i: number) =>
            <SingleProduct item={item} key={i} useRow/>
          )}
        </ul>
        <div>
          <ButtonStyled
            onClick={() => {
              updateCart({
                barcode: "789",
                quantity: 1
              });
            }
            }
          >
        Checkout
          </ButtonStyled>
        </div>
      </div>
    );
  }

  return (
    <div className="row cart-container">
      <div className="w-100 col cart-left-container d-flex flex-column text-center" >
        <h1 className="text-title-black-xl">{ViewTitles.ShoppingCart}</h1>
        <h1 className="fs-2">{EMPTY_CART}</h1>
        <h1 className="fs-4">{EMPTY_CART_SUBTITLE}</h1>
      </div>
      <div className="w-100 col cart-right-container d-flex justify-content-center">
        <img src={emptyCart} className="img-fluid w-100 cart-img" alt={"Working hard or hardly working?"}/>
      </div>
      {/* <Link className="top-header-link" to="/checkout">
        <ButtonStyled >
          Checkout
        </ButtonStyled>
      </Link> */}

    </div>
  );
};

export default ShoppingCart;
