import React, { useMemo } from "react";
import {
  Envelope,
  Facebook,
  Instagram,
  Phone
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

import logoSrc from "../assets/gif/loadingGadegaSmall.gif";
import usFlagSrc from "../assets/us-flag.png";
import { LocationDetails } from "../appStrings/location";
import { FooterViews, ViewPaths } from "../appStrings/views";
import { CompanyDetails, ProductCategories } from "../appStrings/company";
import { FRASE_MAMADORA, LOGO_ALT } from "../appStrings/strings";
import "../scss/Footer.scss";

const PRODUCTS = "Products";
const CONTACT = "Get in Touch";
const LINKS = "Useful links";

const Footer: React.FC = () => {
  const memoizedYear = useMemo(() => DateTime.now().year, []);
  return (
    <footer className="text-center text-lg-start text-muted footer">
      <section className="d-flex justify-content-center justify-content-lg-between p-4">
        <div className="me-5 d-none d-lg-block"/>
        <div className="d-flex me-5">
          <a href={CompanyDetails.facebook_url} className="mx-5" target="_blank" rel="noreferrer" >
            <Facebook size={30} className="footer-icon"/>
          </a>
          <a href={CompanyDetails.instagram_url} className="mx-4" target="_blank" rel="noreferrer">
            <Instagram size={30} className="footer-icon"/>
          </a>
        </div>
      </section>
      <div className="leash"/>
      <section>
        <div className="text-center text-small-white m-4">
          {`"${FRASE_MAMADORA}"`}
        </div>
        <div className="container text-center text-md-start mt-5 footer">
          <div className="row">
            <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
              <img src={logoSrc} className="left-icon col" alt={LOGO_ALT} />
            </div>
            <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 footer-text">
                {PRODUCTS}
              </h6>
              {(Object.keys(ProductCategories) as Array<keyof typeof String>).map((key) => {
                const name = Object.values(ProductCategories)[Object.keys(ProductCategories).indexOf(key)];
                const link = `/shop/${Object.values(ViewPaths)[Object.keys(ViewPaths).indexOf(key)]}`;
                return (
                  <p key={key}>
                    <Link to={link} className="footer-text">
                      {name}
                    </Link>
                  </p>
                );
              }
              ) }
            </div>
            <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4 footer-text">
                {LINKS}
              </h6>
              {(Object.values(FooterViews)).map((name) =>
                <p key={name}>
                  <Link className="footer-text" to={ViewPaths[name]}>{name}</Link>
                </p>
              ) }
            </div>
            <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4 footer-text">
              <h6 className="text-uppercase fw-bold mb-4 footer-text">{CONTACT}</h6>
              <p>
                <Envelope size={15} className="me-3"/>
                {CompanyDetails.email}
              </p>
              <p>
                <Phone size={15} className="me-3"/>
                {CompanyDetails.phone}
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="text-center footer-text centering">
        <img src={usFlagSrc} className="me-2 flag-icon"/>
        {LocationDetails.city}
      </div>
      <div className="text-center footer-text" >
        <Link className="footer-text" to={ViewPaths.Acknowledgements} >{ViewPaths.Acknowledgements}</Link>
      </div>
      <div className="text-center footer-text pb-5">
         @{memoizedYear} {CompanyDetails.name} TM
      </div>
    </footer>
  );
};

export default Footer;
