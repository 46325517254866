import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { Alert, AlertTitle } from "@mui/material";
import { useToastContext } from "../context/ToastContext";

interface BannerProps {
  title?: string
  bodyText: string
  bodyTextStrong?: string
}

const animationVariants = {
  hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
};

const Banner: React.FC<BannerProps> = ({ title, bodyText, bodyTextStrong }) => {
  const { showToast, setShowToast } = useToastContext();

  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [setShowToast, showToast]);

  if (!showToast) {
    return null;
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={animationVariants}
    >
      <Alert>
        <AlertTitle className="text fs-3">{title}</AlertTitle>
        <span className="text-small">
          {bodyText}
          {bodyTextStrong && ("—" && <strong>{bodyTextStrong}</strong>)}
        </span>
      </Alert>
    </motion.div>
  );
};

export default Banner;
