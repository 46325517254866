import React from "react";
import SingleProduct from "./SingleProduct";

import { type Product } from "../@types/product.types";
import "../scss/ProductList.scss";

interface ProductListProps {
  list: any
}

const ProductList: React.FC<ProductListProps> = ({ list }) => {
  return (
    <>
      {list.map((item: Product, i: number) =>
        <SingleProduct item={item} key={i} />
      )}
    </>
  );
};

export default ProductList;
