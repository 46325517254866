import React, { useMemo } from "react";
import "../scss/OutOfStock.scss";
import { OUT_OF_STOCK, SOLD_OUT } from "../appStrings/strings";

interface OutOfStockProps {
  isSoldOut?: boolean
  isOutOfStock?: boolean
}

const OutOfStock: React.FC<OutOfStockProps> = ({ isSoldOut, isOutOfStock }) => {
  const text = useMemo(() => {
    if (isOutOfStock) {
      return OUT_OF_STOCK;
    } else if (isSoldOut) {
      return SOLD_OUT;
    }
    return "";
  }, [isSoldOut, isOutOfStock]);

  return (
    <div className="out-of-stock-container">
      <span className="out-of-stock-text text">{text}</span>
    </div>
  );
};

export default OutOfStock;
