import React, { useCallback, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { X } from "react-bootstrap-icons";
import "../scss/OfferTopBanner.scss";

interface BannerProps {
  bodyText: string
  bodyTextStrong?: string
}

const OfferTopBanner: React.FC<BannerProps> = ({ bodyText, bodyTextStrong }) => {
  const [isVisible, setIsVisible] = useState(true);
  const iconPressHandler = useCallback(() => {
    setIsVisible(false);
  }, []);
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, transition: { duration: 1 } }}
          className="top-banner-container d-flex"
        >
          <span className="text">
            {bodyText} — <strong>{bodyTextStrong}</strong>
          </span>
          <X
            size={30}
            onClick={iconPressHandler}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default OfferTopBanner;
