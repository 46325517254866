import React from "react";

import ProductList from "../components/ProductList";
import { PRODUCT_LIST } from "../products/product";

const Shop: React.FC = () => {
  return (
    <div className="container bg-light w-100 p-4 ">
      <div className="row centering">
        <ProductList list={PRODUCT_LIST}/>
      </div>
    </div>
  );
};

export default Shop;
