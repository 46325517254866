export enum TopHeaderViews {
  Home = "Home",
  Shop = "Shop",
  Favorites = "Favorites",
  About = "About",
}

export enum FooterViews {
  Shop = "Shop",
  Help = "Help",
  Contact = "Contact",
  Precautions = "Precautions"
}

export enum ViewStrings {
  Home = "Home",
  About = "About",
  Contact = "Contact",
  Favorites = "Favorites",
  Help = "Help",
  ShoppingCart = "ShoppingCart",
  Diffusers = "Diffusers",
  EssentialOils = "Essential Oils",
}

export enum ViewTitles {
  Home = "Home",
  About = "About us",
  Checkout = "Checkout",
  ContactUs = "Contact Us",
  Favorites = "Favorites",
  Help = "Help",
  ShoppingCart = "Shopping Cart",
  Acknowledgements = "Acknowledgements",
}

export enum ViewPaths {
  Home = "/",
  About = "about",
  Contact = "contactUs",
  Checkout = "checkout",
  Favorites = "favorites",
  ShoppingCart = "shoppingCart",
  Help = "help",
  Shop = "shop",
  Acknowledgements = "acknowledgements",
  Precautions = "precautions",
  Diffusers = "diffusers",
  EssentialOils = "oils",
  ProductDetails = "productDetails",
  MaterialSafety = "materialSafety",
}
