import React from "react";
import { Routes, Route } from "react-router-dom";

import About from "../containers/About";
import Acknowledgements from "../views/Acknowledgements";
import Home from "../containers/Home";
import ProductDetails from "../views/ProductDetails";
import UnderConstruction from "../containers/UnderConstruction";
import Precautions from "../views/Precautions";
import Favorites from "../containers/Favorites";
import ShoppingCart from "../views/ShoppingCart";
import Help from "../views/Help";
import MaterialSafety from "../views/MaterialSafety";
import Shop from "../containers/Shop";
import ContactUs from "../views/ContactUs";
import Checkout from "../views/Checkout";
import { ViewPaths } from "../appStrings/views";
import "../scss/App.scss";
import "../scss/Common.scss";

const underConstructionPaths: string[] = ["diffusers"];

const Navigator: React.FC = () => {
  return (
    <Routes>
      <Route path={`/${ViewPaths.Home}`} element={<Home />}/>
      <Route path={`/${ViewPaths.About}`} element={<About />}/>
      <Route path={`/${ViewPaths.Acknowledgements}`} element={<Acknowledgements />}/>
      <Route path={`/${ViewPaths.ProductDetails}/:id`} element={<ProductDetails />}/>
      <Route path={`/${ViewPaths.Precautions}`} element={<Precautions />}/>
      <Route path={`/${ViewPaths.Checkout}`} element={<Checkout />}/>
      <Route path={`/${ViewPaths.Contact}`} element={<ContactUs />}/>
      <Route path={`/${ViewPaths.Shop}`} element={<Shop />}/>
      <Route path={`/${ViewPaths.Shop}/:id`}element={<Shop />}/>
      <Route path={`/${ViewPaths.Favorites}`}element={<Favorites />}/>
      <Route path={`/${ViewPaths.Help}`} element={<Help />}/>
      <Route path={`/${ViewPaths.MaterialSafety}/:id`} element={<MaterialSafety />}/>
      <Route path={`/${ViewPaths.ShoppingCart}`} element={<ShoppingCart />}/>
      {/* Working on it */}
      {underConstructionPaths.map(path =>
        <Route path={path} key={"/" + path} element={<UnderConstruction name={path}/>}/>
      )}
    </Routes>
  );
};

export default Navigator;
