import React from "react";
import { List, ListItem } from "@mui/material";

import imgSrc from "../assets/viewImages/physicians.png";
import { PRECAUTIONS, PRECAUTIONS_TITLE } from "../appStrings/precautions";
import "../scss/Precautions.scss";

const Precautions: React.FC = () => {
  return (
    <div className="row pre-container">
      <div className="w-100 col pre-left-container d-flex flex-column justify-content-center text-center" >
        <h1 className="fs-2">{PRECAUTIONS_TITLE}</h1>
        <List>
          {PRECAUTIONS.map(({ title, description }) =>
            <ListItem key={title} >
              <div className="display-line-break">
                <span className="fs-3 pre-title">
                  {title}:
                </span>
                <div/>
                <span className="fs-3">
                  {description}
                </span>
              </div>
            </ListItem>
          ) }
        </List>
      </div>
      <div className="w-100 col pre-section-container d-flex justify-content-center">
        <img src={imgSrc} className="img-fluid w-100 pre-img" alt={"always consult a physician if you have any doubts before using any product"}/>
      </div>
    </div>
  );
};

export default Precautions;
