import React from "react";
import bannerSrc from "../assets/viewImages/underConstruction.png";
import "../scss/UnderConstruction.scss";

interface UnderConstructionProps {
  name: string
}

const UnderConstruction: React.FC<UnderConstructionProps> = ({ name }) => {
  return (
    <div className="row underC-container">
      <div className="w-100 col warning-container d-flex flex-column justify-content-center text-center" >
        <h1 className="text-title-black-xl">{name.toLocaleUpperCase()}</h1>
        <h1 className="text-title-black-2">is under construction.</h1>
        <h1 className="text-title-black-1">We are currently working on this feature! :)</h1>
      </div>
      <div className="w-100 col uc-section-container d-flex justify-content-center">
        <img src={bannerSrc} className="img-fluid w-100 uc-img" alt={"Working hard or hardly working?"}/>
      </div>
    </div>
  );
};

export default UnderConstruction;
