import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Nav, Container } from "react-bootstrap";
import { Link } from "react-router-dom";

import { ViewPaths, TopHeaderViews } from "../appStrings/views";
import "../scss/NavBar.scss";

const NavBar: React.FC = () => {
  return (
    <Navbar expand="lg" bg="black" className="p-3 border" data-bs-theme="dark">
      <Container>
        <Navbar.Toggle aria-controls="navbar-hamburger"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav>
            {(Object.values(TopHeaderViews)).map((name) =>
              <Link className="nav-bar-text" key={name} to={ViewPaths[name]}>{name}</Link>
            ) }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
