import React from "react";
import { StarFill } from "react-bootstrap-icons";

interface RatingProps {
  count: number | undefined
}

const Rating: React.FC<RatingProps> = ({ count }) => {
  if (!count) {
    return null;
  }

  return (
    <div className="d-flex flex-row">
      {
        [...Array(count)].map((e, i) => <StarFill key={i} color={"#efce14"} size={20} className="m-1"/>)
      }
    </div>
  );
};

export default Rating;
