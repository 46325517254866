import React from "react";
import { Cart4 } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

import logoSrc from "../assets/gif/loadingGadegaSmall.gif";
import OfferTopBanner from "./OfferTopBanner";
import { ViewStrings } from "../appStrings/views";
import { BANNER_END_TITLE, BANNER_TITLE, LOGO_ALT } from "../appStrings/strings";
import "../scss/TopHeader.scss";

const TopHeader: React.FC = () => {
  return (
    <section id="top-header" className="sticky-top shadow-lg header-background">
      <div className="header-container">
        <Link className="logo-top-container align-items-center" to="/">
          <img src={logoSrc} className="header-logo" alt={LOGO_ALT} />
        </Link>
        <div className="icon-cart-text-container">
          <Link className="top-header-link" to="/shoppingCart">
            <Cart4 size={20} className="me-3"/>
            <div className="icon-cart-text">
              {ViewStrings.ShoppingCart}
            </div>
          </Link>
        </div>
      </div>
      <OfferTopBanner bodyText={BANNER_TITLE} bodyTextStrong={BANNER_END_TITLE}/>
    </section>
  );
};

export default TopHeader;
