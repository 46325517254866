import React from "react";
import loadingSrc from "../assets/gif/LoadingGadega.gif";
import "../scss/Loading.scss";

interface LoadingProps {
  isLoading?: boolean
}

const Loading: React.FC<LoadingProps> = ({ isLoading = true }) => {
  if (!isLoading) {
    return null;
  };
  return (
    <div className="mx-3 container-loading centering">
      <img src={loadingSrc} className="image-loading" alt={"Loading"} />
    </div>
  );
};

export default Loading;
