import React from "react";
// import { ViewTitles } from "../appStrings/views";
// import imgSrc from "../assets/viewImages/paperPlane.png";
import PaymentForm from "../components/PaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import "../scss/Checkout.scss";

const PUBLIC_KEY = "pk_test_51Om48fDqSnYYNyyvEVOGx8hZJaGOJ3ubvvZYHpGqbO6UiLbrTAgg8Oa82DnxNctXi3UlWiJeK2XAeY9Exshvueam00TFOhIZAZ";
const stripePromise = loadStripe(PUBLIC_KEY);

const Checkout: React.FC = () => {
  return (
    <div className="checkout-container centering">
      <h1>Stripe Payment Form</h1>
      <div>
        <Elements stripe={stripePromise} >
          <PaymentForm />
        </Elements>
      </div>
    </div>
  );
};

export default Checkout;
