// src/components/ContactForm.tsx
import React from "react";
import { motion } from "framer-motion";

// const schema = yup.object().shape({
//   name: yup.string().required("Name is required"),
//   email: yup.string().email("Invalid email").required("Email is required"),
//   comments: yup.string().required("Comments are required")
// });

export interface FormData {
  name: string
  email: string
  comments: string
}

interface ContactFormProps {
  onSubmit: any
}

const ContactForm: React.FC<ContactFormProps> = ({ onSubmit }) => {
  // const { register, handleSubmit, reset, formState: { errors } } = useForm<FormData>({
  //   resolver: yupResolver(schema)
  // });
  // const formRef = useRef(null);

  // const handleFormSubmit = async(data: FormData): Promise<void> => {
  //   try {
  //     await onSubmit(data);
  //     reset();

  //     // Reset the form after successful submission (optional)
  //     // You can use formRef.current.reset(); if you pass a ref to the form
  //   } catch (error) {
  //     // Handle any errors that might occur during form submission
  //   }
  // };

  return (
    <motion.form
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      onSubmit={onSubmit}
      className="contact-form px-5"
      // ref={formRef}
    >
      {/* <div className="contact-text-container d-flex py-2">
        <span className="font-min-size me-4">Name:</span>
        <input type="text" {...register("name")} className="contact-input"/>
        <p className="text-error">{errors.name?.message}</p>
      </div>

      <div className="contact-text-container d-flex py-2">
        <span className="font-min-size me-4">Email:</span>
        <input type="email" {...register("email")} className="contact-input"/>
        <p className="text-error">{errors.email?.message}</p>
      </div>

      <div className="d-flex flex-column ">
        <span className="font-min-size me-4">Comments:</span>
        <textarea {...register("comments")} className="contact-text-area"/>
        <p className="text-error">{errors.comments?.message}</p>
      </div> */}

      <button type="submit">Open Email</button>
    </motion.form>
  );
};

export default ContactForm;
