import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { OOPS, PAGE_NOT_EXIST, PAGE_NOT_FOUND } from "../appStrings/strings";
import { ViewStrings } from "../appStrings/views";
import "../scss/PageNotFound.scss";

const PageNotFound: React.FC = () => {
  return (
    <Container>
      <div className="d-flex align-items-center justify-content-center page-not-found-container bg-light">
        <div className="text-center">
          <h1 className="display-1 fw-bold">404</h1>
          <p className="fs-3">
            <span className="text-danger">{OOPS}</span>
            {"  " + PAGE_NOT_FOUND}
          </p>
          <p className="lead">{PAGE_NOT_EXIST}</p>
          <Link className="btn btn-primary fs-4" to={"/"} >Go {ViewStrings.Home}</Link>
        </div>
      </div>
    </Container>
  );
};

export default PageNotFound;
