import React from "react";
import { List, ListItem } from "@mui/material";
import { Acknowledgements, AcknowledgementsUrls } from "../appStrings/strings";
import { ViewTitles } from "../appStrings/views";
import "../scss/Acknowledgements.scss";

const AcknowledgementsView: React.FC = () => {
  return (
    <div className="help-container container w-100 flex-column">
      <h1 className="fs-2 p-5 centering">{ViewTitles.Acknowledgements}</h1>
      <List
        sx={{
          width: "100%",
          position: "relative",
          overflow: "auto",
          "& ul": { padding: 0 }
        }}
        subheader={<li />}
        className="p-3"
      >
        {(Object.keys(Acknowledgements) as Array<keyof typeof String>).map((name) => (
          <li key={`section-${name}`}>
            <ul>
              <ListItem>
                <span className="text">
                  {Acknowledgements[name as keyof typeof Acknowledgements]} -{" "}
                  <a href={AcknowledgementsUrls[name as keyof typeof AcknowledgementsUrls]} target="_blank" rel="noopener noreferrer">
                    {AcknowledgementsUrls[name as keyof typeof AcknowledgementsUrls]}
                  </a>
                </span>
              </ListItem>
            </ul>
          </li>
        ))}
      </List>
    </div>
  );
};

export default AcknowledgementsView;
