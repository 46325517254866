export interface QuestionAndAnswer {
  id: number
  question: string
  answer: string
}

export const COMMON_QUESTIONS: QuestionAndAnswer[] =
  [
    {
      id: 1,
      question: "What are essential oils?",
      answer: "Essential oils are highly concentrated extracts from plants, capturing their aromatic and therapeutic properties."
    },
    {
      id: 2,
      question: "How do I use essential oils?",
      answer: "Essential oils can be used aromatically (diffusion), topically (applied to the skin), or for beauty and health purposes. Dilution is often recommended for topical use to prevent skin sensitivity."
    },
    {
      id: 3,
      question: "What are the benefits of using essential oils?",
      answer: "Essential oils offer a wide range of benefits, including relaxation, mood enhancement, skin nourishment, respiratory support, and more."
    },
    {
      id: 4,
      question: "Are essential oils safe to ingest?",
      answer: "We don't recommend ingestion of any of our products. Not all oils are safe for internal use, so please avoid doing it."
    },
    {
      id: 5,
      question: "Can essential oils replace medical treatments?",
      answer: "Essential oils can complement wellness routines, but they aren't substitutes for medical treatments. Consult a healthcare provider."
    },
    {
      id: 6,
      question: "Are essential oils eco-friendly?",
      answer: "Essential oils are derived from plants, making them relatively eco-friendly. Choose oils from sustainable sources."
    },
    {
      id: 7,
      question: "How do I choose high-quality essential oils?",
      answer: "Look for 100% pure oils without additives. Choose reputable brands that provide third-party testing and sourcing information."
    },
    {
      id: 8,
      question: "Can pregnant or nursing individuals use essential oils?",
      answer: "Pregnant and nursing individuals should exercise caution with essential oils. Consult a healthcare professional before."
    },
    {
      id: 9,
      question: "Can essential oils cause allergies?",
      answer: "Yes, some individuals might be allergic or sensitive to certain essential oils. Always perform a patch test and start with a small amount. Stop using it if redness, skin sensitivity, or reaction occurs."
    },
    {
      id: 10,
      question: "How do I store essential oils?",
      answer: "Store essential oils in dark glass bottles in a cool, dry place, away from direct sunlight."
    },
    {
      id: 11,
      question: "Can essential oils be used on pets?",
      answer: "Some essential oils can be harmful to pets. Always consult a veterinarian before using essential oils around animals."
    },
    {
      id: 12,
      question: "Can I use essential oils in my skincare routine?",
      answer: "Yes, many essential oils can enhance skincare. Dilute properly and do a patch test to avoid skin reactions."
    },
    {
      id: 13,
      question: "How do essential oils affect mood and emotions?",
      answer: "Essential oils can have a powerful impact on emotions. Aromatherapy with certain oils can help reduce stress, promote relaxation, and uplift mood. Experiment with scents that resonate with you."
    },
    {
      id: 14,
      question: "Can I make my own natural cleaning products with essential oils?",
      answer: "Absolutely! Essential oils like tea tree, lemon, and lavender have natural cleaning properties."
    },
    {
      id: 15,
      question: "Are essential oils culturally significant?",
      answer: "Yes, essential oils have been used for centuries in various cultures for spiritual, medicinal, and wellness purposes. They often hold cultural and historical significance in different societies."
    },
    {
      id: 16,
      question: "Can essential oils help with sleep?",
      answer: "Yes, some essential oils like lavender, chamomile, and cedarwood are known for their calming effects and can promote better sleep. Diffuse these oils or use them in a pre-sleep routine."
    },
    {
      id: 17,
      question: "Do essential oils interact with medications?",
      answer: "Essential oils can interact with certain medications. If you're on medication, consult a healthcare professional before using essential oils to avoid potential interactions."
    },
    {
      id: 18,
      question: "Can I use essential oils during a massage?",
      answer: "Yes, many massage therapists use essential oils to enhance relaxation and soothe muscles during massages."
    },
    {
      id: 19,
      question: "How long do essential oils last?",
      answer: "The shelf life of essential oils varies. Citrus oils tend to have a shorter shelf life, around 1 year, while oils like frankincense and sandalwood can last longer, up to 5-7 years or more. See individual oil labels for more detailed information."
    },
    {
      id: 20,
      question: "Can essential oils be used in gardening?",
      answer: "Yes, some essential oils can be used in gardening to repel insects or promote plant growth. Oils like peppermint, eucalyptus, and neem can be diluted and sprayed on plants, acting as natural pesticides."
    }
  ]
;
